import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styles from './Hero.module.css';

function Hero({ fluidImage, isFullHeight, title, children }) {
  const style = {
    height: isFullHeight ? '90vh' : '50vh',
    minHeight: '20rem',
  };

  return (
    <div className={styles.hero}>
      <Img style={style} fluid={fluidImage} />

      <div className={styles.heroTextBanner}>
        <div className={styles.heroText}>
          <h1 className={styles.primaryText}>{title}</h1>
          <h3 className={styles.secondaryText}>{children}</h3>
        </div>
      </div>
    </div>
  );
}

Hero.propTypes = {
  fluidImage: PropTypes.object.isRequired,
  isFullHeight: PropTypes.bool,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Hero;
