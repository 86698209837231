import React from 'react';
import PropTypes from 'prop-types';
import Link from './Link';
import styles from './Button.module.css';

function Button(props) {
  return props.to ? <Link {...props} /> : <button {...props} />;
}

Button.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
};

Button.defaultProps = {
  className: styles.button,
};

export default Button;
