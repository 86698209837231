import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Button from '../components/Button';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import CTA from '../components/CTA';

function IndexPage() {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "home.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout
      renderHero={() => {
        return (
          <Hero
            fluidImage={data.heroImage.childImageSharp.fluid}
            isFullHeight={true}
            title="Diversity, Equity, &amp; Inclusion Workshops"
          >
            <span style={{ marginRight: 20 }}>
              Guiding organizations in their brave journeys to becoming more
              Equitable, Diverse, and Inclusive.
            </span>
            <Button
              to="/approach/"
              style={{ fontSize: '1.1rem', textShadow: 'none' }}
            >
              Learn More
            </Button>
          </Hero>
        );
      }}
    >
      <SEO title="Home" />

      <p>
        Be Brave guides organizations and individuals on their journeys to
        increase their Equity, Diversity, and Inclusion (EDI) lens through
        individualized workshops, trainings, consulting, and coaching. I guide
        organizations at all stages of their journeys, from those starting out
        to those who are hoping to get to the next level.
      </p>

      <CTA to="/about/">About Me</CTA>
    </Layout>
  );
}

export default IndexPage;
