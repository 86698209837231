import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import styles from './CTA.module.css';

function CTA({ to, children }) {
  return (
    <div className={styles.container}>
      <Button to={to}>{children}</Button>
    </div>
  );
}

CTA.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CTA;
